<template>
    <div class="alarmknown-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="待处理" to="/client/alarm/pending" />
                <van-tab replace title="已知晓" to="/client/alarm/known" />
                <van-tab replace title="已处理" to="/client/alarm/processed" />
            </van-tabs>
        </div>
        <div class="alarm-list">
            <div class="list-container" v-if="dataList && dataList.length > 0">
                <div v-for="(item, index) in dataList" class="list-item" @click="openHandleDialog(item.id)">
                    <div class="item-wrapper">
                        <p class="p1">设备ID：{{ item.pole.equipment_sn }}</p>
                        <p class="p3">
                            <template v-if="item.type === 0">电压报警</template>
                            <template v-if="item.type === 1">倾斜</template>
                            <template v-if="item.type === 2">撞击</template>
                            <template v-if="item.type === 3">巡更过期报警</template>
                        </p>
                    </div>
                    <div class="item-wrapper">
                        <p class="p1">报警时间：</p>
                        <p class="p2">{{ item.created_at }}</p>
                    </div>
                </div>
            </div>
            <van-empty v-else description="暂无数据" />
        </div>
    </div>
    <!--处理报警-->
    <van-action-sheet v-model:show="handleDialog.show" title="请选择">
        <div class="alarm-handling">
            <div class="processed-button">
                <van-button color="#DF5F0B" block @click="handleAlarm(2)">已处理</van-button>
            </div>
        </div>
    </van-action-sheet>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_ALARM_LIST, UPDATE_ALARM_STATUS } from "@/api/alarm";
import { Toast } from "vant";

onMounted(() => {
    getDataList();
});

// 当前tab
let tabActive = ref(1);

// 获取列表
let dataList = ref([]);

let getDataList = () => {
    GET_ALARM_LIST({
        status: 1,
        limit: 10000
    }).then((res) => {
        dataList.value = res.data.data.list;
        console.log(res.data.data);
    });
};

// 处理弹窗
let handleDialog = ref({
    show: false,
    id: null
});

let openHandleDialog = (id) => {
    handleDialog.value = {
        show: true,
        id: id
    };
};

let handleAlarm = (status) => {
    UPDATE_ALARM_STATUS({
        id: handleDialog.value.id,
        status
    }).then((res) => {
        handleDialog.value.show = false;
        if (res.data.code === 200) {
            getDataList();
            Toast.success("处理成功");
        }
    });
};
</script>

<style lang="scss" scoped>
.alarmknown-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .alarm-list {
        .list-container {
            width: 100%;
            margin: 10px 0 0 0;
            padding: 0 15px;
            background: #ffffff;

            .list-item {
                padding: 15px 0;
                border-bottom: 1px dashed #eeeeee;

                &:last-child {
                    border-bottom: none;
                }

                .item-wrapper {
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    &:first-child {
                        margin-top: 0;
                    }

                    P {
                        &:last-child {
                            margin-left: auto;
                        }
                    }
                }

                :deep(.van-checkbox-group) {
                    width: 100%;

                    .van-checkbox {
                        .van-checkbox__label {
                            width: 100%;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                p {
                    font-size: 12px;
                }

                .p1 {
                    width: 200px;
                    color: #000000;
                }

                .p2 {
                    color: #999999;
                }

                .p3 {
                    margin-left: auto;
                    color: #d43030;
                }
            }
        }
    }
}

.alarm-handling {
    font-size: 12px;
    padding: 15px;
}
</style>
